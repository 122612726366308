const accessTokenExpires = 5 * 60 * 1000 - 10;
const refreshTokenExpires = 24 * 3600 * 1000 - 100;
let refreshTimer;
let logoutTimer;

export default {
    async login(context, payload) {
        const url = 'https://stormrose-backend.herokuapp.com/api/token/';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: payload.username,
                password: payload.password
            })
        });
        const responseData = await response.json();
        if (!response.ok) {
            const message = responseData.detail || 'Failed to authenticate!';
            throw new Error(message);
        }
        const refreshExpirationDate = new Date().getTime() + refreshTokenExpires;

        localStorage.setItem('username', payload.username);
        localStorage.setItem('refreshToken', responseData.refresh);
        localStorage.setItem('refreshTokenExpiration', refreshExpirationDate);

        refreshTimer = setTimeout(() => {
            context.dispatch('autoRefresh');
        }, accessTokenExpires);

        context.commit('setUser', {
            username: payload.username,
            ...responseData
        });
        await context.dispatch('getProfile');
    },
    async autoRefresh(context) {
        const url = 'https://stormrose-backend.herokuapp.com/api/token/refresh/';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                refresh: context.getters.refreshToken
            })
        });
        const responseData = await response.json();
        if (!response.ok) {
            throw new Error(
                responseData.message || 'Failed to refresh authentication!'
            );
        }
        clearTimeout(refreshTimer);
        refreshTimer = setTimeout(() => {
            context.dispatch('autoRefresh');
        }, accessTokenExpires);
        context.commit('updateToken', responseData);
    },
    logout(context) {
        localStorage.removeItem('username');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('refreshTokenExpiration');
        clearTimeout(refreshTimer);
        clearTimeout(logoutTimer);
        context.commit('unsetUser');
    },
    async autoLogin(context) {
        const username = localStorage.getItem('username');
        const refreshToken = localStorage.getItem('refreshToken');
        const tokenExpiration = localStorage.getItem('refreshTokenExpiration');

        if (!username || !refreshToken || !tokenExpiration) {
            return;
        }

        const expiresIn = +tokenExpiration - new Date().getTime();
        if (expiresIn < 0) {
            return;
        }

        logoutTimer = setTimeout(() => {
            context.dispatch('autoLogout');
        }, expiresIn);
        context.commit('setUser', {
            username: username,
            access: null,
            refresh: refreshToken
        });
        await context.dispatch('autoRefresh');
        await context.dispatch('getProfile');
    },
    async autoLogout(context) {
        await context.dispatch('logout');
        context.commit('setAutoLogout');
    },
    async register(context, payload) {
        const url = 'https://stormrose-backend.herokuapp.com/account/register/';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: payload.username,
                email: payload.email
            })
        });
        const responseData = await response.json();
        if (!response.ok || responseData.status === 'ko') {
            const message = responseData.message || 'Failed to register!';
            throw new Error(message);
        }
    },
    async getProfile(context) {
        const token = context.getters.token;
        const url = 'https://stormrose-backend.herokuapp.com/account/';
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        const responseData = await response.json();
        if (!response.ok || responseData.status === 'ko') {
            const message = responseData.message || 'Failed to retrieve profile';
            throw new Error(message);
        }
        context.commit('setProfile', responseData.data);
    },
    async verifyProfile(context) {
        const token = context.getters.token;
        const url= 'https://stormrose-backend.herokuapp.com/account/verify/';
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        const responseData = await response.json();
        if (!response.ok || responseData.status === 'ko') {
            const message = responseData.message || 'Failed to verify account';
            throw new Error(message);
        }
        await context.dispatch('getProfile');
    },
    async changePassword(context, payload) {
        const token = context.getters.token;
        const url= 'https://stormrose-backend.herokuapp.com/account/password/';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                old_password: payload.currentPassword,
                new_password: payload.newPassword
            })
        });
        const responseData = await response.json();
        if (!response.ok || responseData.status === 'ko') {
            const message = responseData.message || 'Failed to change password';
            throw new Error(message);
        }
    },
    async changeEmail(context, payload) {
        const token = context.getters.token;
        const url= 'https://stormrose-backend.herokuapp.com/account/email/';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                old_email: payload.currentEmail,
                new_email: payload.newEmail
            })
        });
        const responseData = await response.json();
        if (!response.ok || responseData.status === 'ko') {
            const message = responseData.message || 'Failed to update email address';
            throw new Error(message);
        }
        await context.dispatch('getProfile');
    },
    async addGroup(context, payload) {
        const token = context.getters.token;
        const url= 'https://stormrose-backend.herokuapp.com/account/group/add/';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                group: payload.groupName
            })
        });
        const responseData = await response.json();
        if (!response.ok || responseData.status === 'ko') {
            const message = responseData.message || `Failed to add group ${payload.groupName} to account`;
            throw new Error(message);
        }
        await context.dispatch('getProfile');
    },
    async verifyGroups(context) {
        const token = context.getters.token;
        const url= 'https://stormrose-backend.herokuapp.com/account/group/verify/';
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        const responseData = await response.json();
        if (!response.ok || responseData.status === 'ko') {
            const message = responseData.message || 'Failed to verify groups';
            throw new Error(message);
        }
        await context.dispatch('getProfile');
    },
    async toggleNotify(context) {
        const notify = context.getters.wantsNotify;
        const action = notify ? 'unsubscribe' : 'subscribe';
        const url= 'https://stormrose-backend.herokuapp.com/account/notify/';
        const token = context.getters.token;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                action: action
            })
        });
        const responseData = await response.json();
        if (!response.ok || responseData.status === 'ko') {
            const message = responseData.message || `Failed to ${action} to/from notification emails`;
            throw new Error(message);
        }
        await context.dispatch('getProfile');
    },
    async passwordResetRequest(context, payload) {
        const url= 'https://stormrose-backend.herokuapp.com/account/password/reset/';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: payload.username,
                email: payload.email
            })
        });
        const responseData = await response.json();
        if (!response.ok || responseData.status === 'ko') {
            const message = responseData.message || 'Failed to initiate password reset!';
            throw new Error(message);
        }
    },
    async passwordResetConfirm(context, payload) {
        const url= 'https://stormrose-backend.herokuapp.com/account/password/reset/confirm/';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: payload.username,
                token: payload.token,
                password: payload.password
            })
        });
        const responseData = await response.json();
        if (!response.ok || responseData.status === 'ko') {
            const message = responseData.message || 'Failed to complete password reset!';
            throw new Error(message);
        }
    }
};