<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>Stormrose</strong> is a virtual aircraft leasing company based on
        <a href="https://www.fseconomy.net/">FSEconomy</a>,
        an economy simulation game for flight simulators.
      </p>
      <p>
        <router-link to="/legal">Legal Information</router-link> | <router-link to="/privacy">Privacy Policy</router-link>
      </p>
    </div>
  </footer>
</template>
