export default {
    aircraft(state) {
        return state.aircraft;
    },
    hasAircraft(state) {
        return state.aircraft && state.aircraft.length > 0;
    },
    shouldUpdate(state) {
        const lastFetch = state.lastFetch;
        if (!lastFetch) {
            return true;
        }
        const currentTimestamp = new Date().getTime();
        return ((currentTimestamp - lastFetch) / 1000 > 60);
    }
};