import { createStore } from 'vuex';
import aircraftModule from './aircraft/index.js';
import authModule from './auth/index.js';
import contractModule from './contracts/index.js';

const store = createStore({
    modules: {
        aircraft: aircraftModule,
        auth: authModule,
        contracts: contractModule
    }
});

export default store;