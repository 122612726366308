<template>
  <!--<the-header></the-header>-->
  <the-header-no-menu></the-header-no-menu>
  <div class="container">
    <router-view v-slot="slotProps">
      <transition name="route" mode="out-in">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
  </div>
  <the-footer></the-footer>
</template>

<script>
/*import TheHeader from './components/layout/TheHeader.vue';*/
import TheHeaderNoMenu from './components/layout/TheHeaderNoMenu.vue';
import TheFooter from './components/layout/TheFooter.vue';

export default {
  name: 'App',
  components: {
    /*TheHeader,*/
    TheHeaderNoMenu,
    TheFooter
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters['auth/didAutoLogout'];
    }
  },
  watch: {
    didAutoLogout(curVal, oldVal) {
      if (!!curVal && (curVal !== oldVal)) {
        this.$router.replace('/aircraft');
      }
    }
  },
  created() {
    this.$store.dispatch('auth/autoLogin');
  },
}
</script>

<style>
.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
