import { createRouter, createWebHistory } from 'vue-router';

const AircraftList = () => import('./pages/aircraft/AircraftList.vue');
const UserLogin = () => import('./pages/auth/UserLogin.vue');
const UserSignup = () => import('./pages/auth/UserSignup.vue');
const UserPassword = () => import('./pages/auth/UserPassword.vue');
const UserAccount = () => import('./pages/account/UserAccount.vue');
const UserGroups = () => import('./pages/account/UserGroups.vue');
const ContractList = () => import('./pages/contracts/UserContracts.vue');
const AboutPage = () => import('./pages/info/AboutPage.vue');
const TermsOfService = () => import('./pages/info/TermsOfService.vue');
const LegalPage = () => import('./pages/info/LegalPage.vue');
const PrivacyPage = () => import('./pages/info/PrivacyPage.vue');
const OutOfService = () => import('./pages/info/OutOfService');

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            /*redirect: '/aircraft'*/
            component: OutOfService
        },
        {
            name: 'aircraft',
            path: '/aircraft',
            component: AircraftList
        },
        {
            name: 'login',
            path: '/login',
            component: UserLogin
        },
        {
            name: 'signup',
            path: '/signup',
            component: UserSignup
        },
        {
            name: 'password',
            path: '/password/:token?',
            props: true,
            component: UserPassword
        },
        {
            name: 'account',
            path: '/account',
            component: UserAccount
        },
        {
            name: 'groups',
            path: '/groups',
            component: UserGroups
        },
        {
            name: 'contracts',
            path: '/contracts',
            component: ContractList
        },
        {
            name: 'about',
            path: '/about',
            component: AboutPage
        },
        {
            name: 'terms',
            path: '/terms',
            component: TermsOfService
        },
        {
            name: 'legal',
            path: '/legal',
            component: LegalPage
        },
        {
            name: 'privacy',
            path: '/privacy',
            component: PrivacyPage
        }
    ],
    linkExactActiveClass: 'is-active'
});

export default router;