export default {
    shouldUpdate(state) {
        const lastFetch = state.lastFetch;
        if (!lastFetch) {
            return true;
        }
        const currentTimestamp = new Date().getTime();
        return ((currentTimestamp - lastFetch) / 1000 > 60);
    },
    contracts(state) {
        return state.contracts;
    },
    hasContracts(state) {
        return state.contracts && state.contracts.length > 0;
    },
    pendingContracts(state) {
        if (state.contracts && state.contracts.length > 0) {
            return state.contracts.filter(contract => (!contract.paid_until && contract.status === 'A'));
        } else {
            return [];
        }
    },
    activeContracts(state) {
        if (state.contracts && state.contracts.length > 0) {
            return state.contracts.filter(contract => (!!contract.paid_until && contract.status === 'A'));
        } else {
            return [];
        }
    },
    closedContracts(state) {
        if (state.contracts && state.contracts.length > 0) {
            return state.contracts.filter(contract => contract.status === 'C');
        } else {
            return [];
        }
    },
    hasPendingContracts(state) {
        if (state.contracts && state.contracts.length > 0) {
            const pending = state.contracts.filter(contract => (!contract.paid_until && contract.status === 'A'));
            return (pending.length > 0);
        } else {
            return false;
        }
    },
    hasActiveContracts(state) {
        if (state.contracts && state.contracts.length > 0) {
            const active = state.contracts.filter(contract => (!!contract.paid_until && contract.status === 'A'));
            return (active.length > 0);
        } else {
            return false;
        }
    },
    hasClosedContracts(state) {
        if (state.contracts && state.contracts.length > 0) {
            const active = state.contracts.filter(contract =>contract.status === 'C');
            return (active.length > 0);
        } else {
            return false;
        }
    }

};