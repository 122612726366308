export default {
    async loadContracts(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdate) {
            return;
        }
        let token = context.rootGetters['auth/token'];
        let attempts = 0;
        while (!token && attempts < 3) {
            await new Promise(r => setTimeout(r, 500));
            token = context.rootGetters['auth/token'];
            attempts++;
        }
        if (!token) {
            throw new Error("Unable to retrieve authentication token");
        }
        const url = 'https://stormrose-backend.herokuapp.com/leasing/contracts/';
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        const responseData = await response.json();
        if (!response.ok) {
            const message = responseData.message || 'Failed to retrieve contracts';
            throw new Error(message);
        }
        context.commit('setContracts', responseData);
        context.commit('setFetchTimestamp');
    },
    async refreshPayments(context) {
        let token = context.rootGetters['auth/token'];
        let attempts = 0;
        while (!token && attempts < 3) {
            await new Promise(r => setTimeout(r, 500));
            token = context.rootGetters['auth/token'];
            attempts++;
        }
        if (!token) {
            throw new Error("Unable to retrieve authentication token");
        }
        const url= 'https://stormrose-backend.herokuapp.com/leasing/verify/';
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        const responseData = await response.json();
        if (!response.ok || responseData.status === 'ko') {
            const message = responseData.message || 'Failed to verify account';
            throw new Error(message);
        }
        await context.dispatch('loadContracts', {forceRefresh: true});
    }
};