<template>
  <header>
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container has-text-centered">
          <p class="title">
            <img src="../../assets/Logo_White.png" alt="Stormrose Logo">
          </p>
          <p class="subtitle">
            Aircraft Leasing
          </p>
        </div>
      </div>
    </section>
    <!--
    <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
      <div class="container">
        <div class="navbar-brand">
          <router-link to="/" class="navbar-item">
            <svg-icon type="mdi" :path="homeIcon">&nbsp;</svg-icon>&nbsp;
            Home
          </router-link>
          <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="theNavBar"
             @click="toggleNavBar">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div class="navbar-menu" :class="{'is-active': navbarActive}" id="theNavBar">
          <div class="navbar-start">
            <router-link to="/about" class="navbar-item">About</router-link>
            <router-link to="/terms" class="navbar-item">Terms of Service</router-link>
          </div>
          <div class="navbar-end">
            <router-link to="/login" class="navbar-item" v-if="!isLoggedIn">
              <svg-icon type="mdi" :path="loginIcon"></svg-icon>&nbsp;Sign In
            </router-link>
            <div class="navbar-item has-dropdown is-hoverable" v-if="isLoggedIn">
              <a class="navbar-link">
                <svg-icon type="mdi" :path="accountIcon">&nbsp;</svg-icon>&nbsp;
                {{ userName }}</a>
              <div class="navbar-dropdown">
                <router-link to="/account" class="navbar-item">
                  <svg-icon type="mdi" :path="accountIcon">&nbsp;</svg-icon>&nbsp;&nbsp;My Account
                </router-link>
                <router-link to="/groups" class="navbar-item">
                  <svg-icon type="mdi" :path="groupIcon">&nbsp;</svg-icon>&nbsp;&nbsp;My Groups
                </router-link>
                <router-link to="/contracts" class="navbar-item">
                  <svg-icon type="mdi" :path="contractsIcon">&nbsp;</svg-icon>&nbsp;&nbsp;My Contracts
                </router-link>
                <hr class="navbar-divider">
                <a class="navbar-item" v-if="isLoggedIn" @click="logout">
                  <svg-icon type="mdi" :path="logoutIcon">&nbsp;</svg-icon>&nbsp;&nbsp;Logout</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    -->
  </header>
</template>

<script>
export default {
  name: "TheHeaderNoMenu",
  data() {
    return {
      navbarActive: false
    }
  }
}
</script>
