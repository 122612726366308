export default {
    async loadAircraft(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdate) {
            return;
        }
        const response = await fetch('https://stormrose-backend.herokuapp.com/leasing/aircraft/', {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || 'Failed to fetch aircraft data!');
        }
        context.commit('setAircraft', data);
        context.commit('setFetchTimestamp');
    },
    async submitLeaseRequest(context, payload) {
        const token = context.rootGetters['auth/token'];
        const url= 'https://stormrose-backend.herokuapp.com/leasing/reserve/';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                aircraft: payload.aircraft,
                target: payload.target
            })
        });
        const responseData = await response.json();
        if (!response.ok || responseData.status === 'ko') {
            const message = responseData.message || 'Failed to reserve aircraft';
            throw new Error(message);
        }
    },
};