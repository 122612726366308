export default {
    isAuthenticated(state) {
        return !!state.username && !!state.accessToken && !!state.refreshToken;
    },
    refreshToken(state) {
        return state.refreshToken;
    },
    didAutoLogout(state) {
        return state.didAutoLogout;
    },
    username(state) {
        return state.username;
    },
    token(state) {
        return state.accessToken;
    },
    profile(state) {
        return state.profile;
    },
    hasProfile(state) {
        return !!state.profile;
    },
    targets(state, getters) {
        let targetList = [];
        if (!getters.isAuthenticated) {
            return targetList;
        }
        targetList.push(state.username);
        if (!getters.hasProfile) {
            return targetList;
        }
        for (const group of state.profile.groups) {
            targetList.push(group);
        }
        return targetList;
    },
    verifiedGroups(state, getters) {
      if (!getters.isAuthenticated || !getters.hasProfile) {
          return [];
      }
      return state.profile.groups.filter(group => group.verified);
    },
    isVerified(state) {
        if (state.profile) {
            return state.profile.verified;
        } else {
            return false;
        }
    },
    wantsNotify(state) {
        if (state.profile) {
            return state.profile.notify;
        } else {
            return false;
        }
    }
};