export default {
    setUser(state, payload) {
        state.username = payload.username;
        state.accessToken = payload.access;
        state.refreshToken = payload.refresh;
        state.didAutoLogout = false;
    },
    updateToken(state, payload) {
        state.accessToken = payload.access;
    },
    unsetUser(state) {
        state.username = null;
        state.accessToken = null;
        state.refreshToken = null;
        state.profile = null;
        state.didAutoLogout = false;
    },
    setAutoLogout(state) {
        state.didAutoLogout = true;
    },
    setProfile(state, payload) {
        state.profile = payload;
    }
};